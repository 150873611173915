import React from 'react'
import { Helmet } from "react-helmet"
import { ArrowRightIcon } from '@heroicons/react/solid'

import Header from '../../components/header'

import img from '../../borough.jpg'

export default function Home() {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Home - CoffeeHouses</title>
      </Helmet>
      <Header />
      <main className="flex flex-col justify-center">
        <section className="px-3 pt-8 bg-brand-cyan md:pt-28 md:px-0">
          <div className="flex flex-col items-center mx-auto text-white md:mx-0">
            <span className="text-sm font-medium tracking-widest text-current">FOR THE SPECIALTY COFFEE FAN!</span>
            <span className="pt-12 text-4xl text-center md:text-6xl font-serifblack">Find the best local coffee spots.</span>
            <span className="text-xl font-medium text-center pt-14 pb-14">CoffeeHouses is here to ensure you never drink a bad cup of <br />coffee when out and about, ever again.</span>


            {/* <form action="#" class="sm:max-w-xl sm:mx-auto mx-1">
              <div class="sm:flex">
                <div class="min-w-0 flex-1">
                  <label for="email" class="sr-only">Email address</label>
                  <input id="email" type="email" placeholder="Enter your location" class="block w-full px-4 py-3 rounded-md border-0 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-300 focus:ring-offset-gray-900" />
                </div>
                <div class="mt-3 sm:mt-0 sm:ml-3">
                  <button
                    type="submit"
                    className="flex flex-row items-center justify-center w-full px-4 py-3 font-medium text-white bg-yellow-400 rounded-md shadow hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-300 focus:ring-offset-gray-900"
                  >
                    Go
                    </button>
                    </div>
                    </div>
                    <p class="mt-3 text-sm text-gray-300 sm:mt-4">Start your free 14-day trial, no credit card necessary. By providing your email, you agree to our <a href="#" class="font-medium text-white">terms of service</a>.</p>
                  </form> */}

              <div className="max-w-md px-4 mx-auto sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
                <div>
                  {/* <a
                    href="#"
                    className="inline-flex items-center p-1 pr-2 text-white bg-black rounded-full sm:text-base lg:text-sm xl:text-base hover:text-gray-200"
                    >
                    <span className="px-3 py-0.5 text-white text-xs font-semibold leading-5 uppercase tracking-wide bg-indigo-500 rounded-full">
                    We're hiring
                    </span>
                    <span className="ml-4 text-sm">Visit our careers page</span>
                    <ChevronRightIcon className="w-5 h-5 ml-2 text-gray-500" aria-hidden="true" />
                    </a>
                    <h1 className="mt-4 text-4xl font-extrabold tracking-tight text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                    <span className="block">A better way to</span>
                    <span className="block text-indigo-400">ship web apps</span>
                  </h1> */}
                  {/* <p className="mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                    Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui Lorem cupidatat commodo. Elit sunt
                    amet fugiat veniam occaecat fugiat.
                  </p> */}
                  <div className="mt-10 sm:mt-12">
                    <form action="#" className="sm:max-w-xl sm:mx-auto lg:mx-0">
                      <div className="sm:flex">
                        <div className="flex-1 min-w-0">
                          <label htmlFor="email" className="sr-only">
                            Email address
                          </label>
                          <input
                            id="email"
                            type="email"
                            placeholder="Enter your email"
                            className="block w-full px-4 py-3 text-base text-gray-900 placeholder-gray-500 border-0 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-300 focus:ring-offset-gray-900"
                          />
                        </div>
                        <div className="mt-3 sm:mt-0 sm:ml-3">
                          <button
                            type="submit"
                            className="block w-full px-4 py-3 font-medium text-white bg-yellow-500 rounded-md shadow hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-300 focus:ring-offset-gray-900"
                          >
                            <span className="flex flex-row justify-center px-2">
                              Go
                              <ArrowRightIcon className="w-8 pl-3 text-white" />
                            </span>
                          </button>
                        </div>
                      </div>
                      <p className="mt-3 text-sm text-gray-300 sm:mt-4">
                        Start your free 14-day trial, no credit card necessary. By providing your email, you agree to
                        our{' '}
                        <a href="#" className="font-medium text-white">
                          terms of service
                        </a>
                        .
                      </p>
                    </form>
                  </div>
                </div>
              </div>

            <div className="z-20 max-w-4xl">
              <img src={img} className="pt-24 rounded-sm " alt="An example of a coffee house" />
            </div>
          </div>
        </section>
        <section className="relative z-10 -mt-4 bg-white h-44">

        </section>
      </main>
    </div>
  )
}